<template>
  <div
    class="dashboard"
    v-if="$store.getters['auth/getUser']['type']['id'] != 1"
  >
    <div class="dashboard__score dashboard-score">
      <div class="dashboard-score__container dashboard-score__container--no-bg">
        <radial-bar-chart
          v-if="radialBarChartData != null"
          :data="radialBarChartData"
        />
        <!-- <v-btn text class="mt-n10" :style="{ 'margin-left': selectedLanguage === 'pt' ? '14px' : '4px', }" 
        :to="{ name: 'Leaderboard' }" v-if="radialBarChartData">
          {{ $t("leaderboard") }}
        </v-btn> -->
      </div>
      <div class="dashboard-score__container" :style="{ 'margin': selectedLanguage === 'pt' ? '5px' : '', }">
        <h1 class="primary--text" >
          {{ salesIncreaseOpportunity }} 
        </h1>
        <p class="">{{ $t("salesIncreaseOpportunity") }}</p>
      </div>
      <div class="dashboard-score__container" :style="{ 'margin': selectedLanguage === 'pt' ? '5px' : '', 'min-height':selectedLanguage === 'pt' ? '125px' : '', }">
        <h1 class="primary--text">{{ potentialNewProducts }}</h1>
        <p class="">{{ $t("potentialNewProducts") }}</p>
      </div>
      <div class="dashboard-score__container" :style="{ 'margin': selectedLanguage === 'pt' ? '5px' : '', }">
        <h1 class="primary--text" >
          {{ behaviouralChanges }}
        </h1>
        <p class="">{{ $t("behaviouralChanges") }}</p>
      </div>
    </div>

    <!--
      <v-col class="d-flex flex-column justify-space-between" cols="3">
        <v-card
          class="d-flex align-center rounded-lg"
          color="white"
          shaped
          elevation="1"
        >
          <v-col>
            <center>
              <h1 class="red--text">8</h1>
              <p class="secondary--text">Anomalies in sales</p>
            </center>
          </v-col>
        </v-card>
        <v-card class="rounded-lg" color="white" shaped elevation="1">
          <v-col class="d-flex flex-column align-center">
            <h1 class="yellow--text">8</h1>
            <p class="secondary--text">Basket core size</p>
          </v-col>
        </v-card>
      </v-col>
      -->
    <!-- <v-row>
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          color="white"
          elevation="0"
        >
          <l-map
            style="height: 400px; z-index: 200"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker
              v-for="item in stores"
              :key="item.name"
              :lat-lng="item.latitude_longitude"
              :icon="getIcon(item)"
            >
              <l-popup>
                <div>
                  {{ item.name }}
                  <p>
                    {{ item.city }}
                  </p>
                </div>
              </l-popup>
              <l-tooltip :options="{ permanent: true }">
                <div>{{ getScore(item.score) }}</div>
              </l-tooltip>
            </l-marker>
          </l-map>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          color="white"
          elevation="0"
        >
          <v-col cols="3" class="ml-n9">
            <v-row class="d-flex justify-center">
              <h1 class="primary--text">{{ segments }}</h1>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class="">{{ $t("segments") }}</p>
            </v-row>
            <v-row class="d-flex justify-center">
              <div>
                <router-link class="segments__link" to="/segments">
                  {{ $t("explore") }}
                  <v-icon color="#002147" small center>mdi-open-in-new</v-icon>
                </router-link>
              </div>
            </v-row>
          </v-col>
          <v-col cols="9" class="ml-4">
            <!--<stacked-column-chart
              v-if="radarChartData != null"
              :data="radarChartData"
              :months="months"
            />-->
            <horizontal-bar-chart
              v-if="horizontalBarChartData != null"
              :data="horizontalBarChartData"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12" >
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          color="white"
          elevation="0"
        >
          <v-col cols="3" class="ml-n9">
            <v-row class="d-flex justify-center">
              <h1 class="primary--text">{{ transactions }} {{ $t("in100") }}</h1>
            </v-row>
            <v-row class="d-flex justify-center">
              <p class=""> {{ $t("transactions") }}</p>
            </v-row>
          </v-col>
          <v-col cols="9" class="ml-4">
            <line-chart
              v-if="lineChartData != null"
              :series="lineChartData"
              :cats="cats"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12">
        <v-card style="border-radius: 30px" color="white" elevation="0">
          <v-card-title>
            <h5>Sales anomalies detected</h5>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersAnomalies"
            :items="anomalies"
            :items-per-page="5"
            :search="search"
          > -->
      <!-- Customize Columns -->
      <!-- <template v-slot:[`item.optimization`]="{ item }">
              <div class="data-table-optimization-layout">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    small
                    color="white--text"
                    class="mr-2 mt-2 mb-2"
                    :style="{
                      'background-color': hover ? '#002147' : '#424242',
                    }"
                    link
                    :to="{
                      name: 'Analytics',
                      params: {
                        analyticForm: 'Anomalies',
                        product: item.product,
                      },
                    }"
                  >
                    <v-icon small left>mdi-google-analytics</v-icon>
                    Run
                  </v-btn>
                </v-hover>
              </div>
            </template> -->
      <!-- !Customize Columns -->
      <!-- </v-data-table>
        </v-card>
      </v-col> -->
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 30px" color="white" elevation="0">
          <v-card-title>
            <h5>
              Products from other stores that should be added (market trends)
            </h5>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchTrends"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersTrends"
            :items="trends"
            :items-per-page="5"
            :search="searchTrends"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row> -->
    <InsightsTables />
  </div>
  <div v-else>
    <v-img alt="Logo" src="@/assets/logo-dark.png" contain aspect-ratio="4">
    </v-img>
  </div>
</template>

<script>
import HorizontalBarChart from "@/components/charts/HorizontalBar.vue";
import RadialBarChart from "@/components/charts/RadialBar.vue";
import LineChart from "@/components/charts/Line.vue";
import { latLng } from "leaflet";
import AppError from "@/utils/AppError.js";
//import { LMap, LTileLayer, LPopup, LMarker, LTooltip } from "vue2-leaflet";
import L from "leaflet";
import InsightsTables from "@/components/InsightsTables.vue";
export default {
  name: "Dashboard",

  components: {
    HorizontalBarChart,
    LineChart,
    RadialBarChart,
    InsightsTables,
    // LMap,
    // LTileLayer,
    // LPopup,
    // LMarker,
    // LTooltip,
  },

  mounted: function () {
    this.scrollToTop();
    this.selectedLanguage = localStorage.getItem("language")
  },

  data() {
    return {
      zoom: 2.5,
      center: latLng(47.41322, -1.219482),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      stores: null,
      currentZoom: null,
      currentCenter: latLng(47.41322, -1.219482),
      mapOptions: {
        zoomSnap: 0.5,
        attributionControl: false,
      },

      search: "",
      searchTrends: "",
      horizontalBarChartData: null,
      lineChartData: null,
      radialBarChartData: null,
      radarChartData: null,
      cats: null,
      months: null,
      salesIncreaseOpportunity: null,
      potentialNewProducts: null,
      coldAreas: null,
      behaviouralChanges: null,
      segments: null,
      transactions: null,
      anomalies: [],
      trends: [],
      store: null,
      selectedTable: "table1",
      selectedLanguage: null,
    };
  },

  computed: {
    headersAnomalies() {
      return [
        { text: this.$t("sku"), value: "sku", width: 100 },
        { text: this.$t("product"), value: "product", width: 200 },
        {
          text: "Potential growth (%)",
          value: "growth",
          width: 100,
        },
        {
          text: "Optimization",
          value: "optimization",
          align: "center",
          filterable: false,
          sortable: false,
          width: 100,
        },
      ];
    },
    headersTrends() {
      return [
        { text: "Segment (target)", value: "segment", width: 300 },
        { text: "New product", value: "new_product", width: 100 },
        {
          text: "from store",
          value: "store",
          width: 100,
        },
      ];
    },
  },

  methods: {
    /**
     * Auto scroll page to the top.
     * @public
     */
    scrollToTop() {
      window.scrollTo(0, 0);
      if (this.$store.getters["auth/getUser"]["type"]["id"] != 1) {
        this.getStores();
        this.getAnomalies();
        this.getTrends();
      }
    },
    /**
     * Get the icon color based on which store is selected.
     * @param {store} item - the selected store.
     * @public
     */
    getIcon(item) {
      var redIcon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      var blueIcon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      var store = JSON.parse(localStorage.getItem("store"));

      if (store) {
        if (item.name === store.name) {
          return redIcon;
        } else {
          return blueIcon;
        }
      } else {
        if (item.name === this.stores[0].name) {
          return redIcon;
        } else {
          return blueIcon;
        }
      }
    },
    /**
     * Zoom upadte on icon map.
     * @public
     */
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    /**
     * Center the icon map.
     * @public
     */
    centerUpdate(center) {
      this.currentCenter = center;
    },
    /**
     * Get anomalies.
     * @public
     */
    getAnomalies() {
      this.anomalies = [
        {
          sku: "117836",
          product: "G TA M367 IMPASTO FRESCO PANE/PIZZA 1KG",
          growth: 25,
        },
        {
          sku: "116716",
          product: "ORT LENTICCHIE SGOC.240G",
          growth: 18,
        },
        {
          sku: "550115",
          product: "PIEMONTE BARBERA DOC 13%VOL 0.75L",
          growth: 9,
        },
        {
          sku: "156023",
          product: "M23567 PY FILONCINO FERRARESE 250G",
          growth: 31,
        },
        {
          sku: "573589",
          product: "CUBA LIBRE E MOJITO 5% VOL 275ML",
          growth: 28,
        },
      ];
    },
    /**
     * Get trends.
     * @public
     */
    getTrends() {
      this.trends = [
        {
          segment:
            "GM SALMONE SCOZZESE AFFUM, 100G, INS.GENTILE ASDASDASD ASSAIIASDASDIASDBIASDBIASDBASDUIISDBUAIASDSDBIAASDUIASDUIASDA FASFASFSFASFSFAFSSAFASFASFAFSSFA",
          new_product: "RUCOLA 125G NATURA E",
          store: "Dison",
        },
        {
          segment:
            "IOASFHIOASFAFHOASFASFASFHIOASFIOASFIOASFHIOAFHFIOASASFHIOASFASFHIOASASFOF IOFHIOASASFHASF FHIOAAASFHIOASFHIO FH HIOASF HIOSFAHF FASFHISF HA H OASFOFHASFHIFASAS HIHI AASF A IO",
          new_product: "PY PATATE STEAKHOUSE 750G",
          store: "Feira Nova",
        },
      ];
    },
    /**
     * Get the store score for icon map.
     * @public
     */
    getScore(val) {
      return (val * 5) / 100 + " / 5";
    },
    /**
     * Get the list of company stores.
     * @public
     */
    getStores() {
      var user = this.$store.getters["auth/getUser"];
      let url = this.$config.api.route.usersStores;
      url = url.replace(":id", user.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.stores = resp.data;
          var lastStore = localStorage.getItem("store");
          this.store = lastStore ? JSON.parse(lastStore) : this.stores[0];
          this.getData();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {});
    },
    /**
     * Get data.
     * @public
     */
    getData() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.dashboardData;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          console.log(resp.data)
          this.salesIncreaseOpportunity = resp.data.sales_increase_opportunity !== '-' ? resp.data.sales_increase_opportunity + "%" : resp.data.sales_increase_opportunity;
          this.potentialNewProducts = resp.data.potential_new_products;
          this.coldAreas = resp.data.cold_areas;
          this.behaviouralChanges = resp.data.behavioural_changes;
          this.segments = resp.data.segments;
          this.transactions = resp.data.transactions;
          this.getDataCharts();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {});
    },
    /**
     * Get data to the charts.
     * @public
     */
    getDataCharts() {
      let url = this.$config.api.route.dashboardChartData;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          console.log(resp.data);
          this.horizontalBarChartData = resp.data.horizontal_bar_chart_data;
          this.radialBarChartData = resp.data.radial_bar_chart_data;

          this.lineChartData = [
            {
              name: this.$t("allTransactions"),
              data: resp.data.line_chart_data.total_daily_transactions,
            },
            {
              name: this.$t("transactionsSegments"),
              data: resp.data.line_chart_data.total_match_daily_transactions,
            },
          ];
          this.cats = resp.data.line_chart_data.transaction_dates;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
      /*this.radarChartData = [
        {
          name: "Product 1",
          data: [3, 56, 75, 50, 20, 21],
        },
        {
          name: "Product 2",
          data: [80, 70, 15, 99, 80, 10],
        },
        {
          name: "Product 3",
          data: [40, 73, 50, 89, 80, 23],
        },
        {
          name: "Product 4",
          data: [10, 52, 50, 89, 80, 23],
        },
        {
          name: "Product 5",
          data: [30, 45, 50, 22, 80, 20],
        },
      ];*/

      //this.months = ["Jan", "Feb", "Mar", "Abr", "May", "Jun"];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: unset !important;
}

.v-card {
  box-shadow: none !important;
}

.dashboard-score {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  margin-bottom: 3rem;
  justify-content: space-between;

  &__container {
    text-align: center;
    background: white;
    border-radius: 10px;
    min-width: 210px;
    padding: 5px;

    &--no-bg {
      background: none;
      padding: 0;
    }
  }
}

.segments {
  &__link {
    text-decoration: none;
    display: block;
    margin-bottom: 4rem;
  }
}
</style>
