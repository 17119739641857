<template>
  <div class="tables container">
    <div class="tables__radio tables-radio">
      <input
        class="tables-radio__input"
        type="radio"
        name="tableSelector"
        id="table1Radio"
        value="table1"
        v-model="selectedTable"
      />
      <label class="tables-radio__label" for="table1Radio">
        {{ $t("best10Performances") }}
      </label>
    </div>
    <div class="tables__radio tables-radio">
      <input
        class="tables-radio__input"
        type="radio"
        name="tableSelector"
        id="table2Radio"
        value="table2"
        v-model="selectedTable"
      />
      <label class="tables-radio__label" for="table2Radio">
        {{ $t("worst10Performances") }}
      </label>
    </div>
    <h3 class="tables__title">{{ $t("salesForecast") }}<span> {{ $t("3months") }}</span></h3>
    <table class="tables__container" v-if="selectedTable === 'table1'">
      <thead class="tables__header">
        <tr class="tables__row">
          <th class="tables__cell-title" scope="col">{{ $t("sku") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("description") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("expectedGrowth") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in bestPerformances" :key="item.product__sku">
          <td class="tables__cell">{{ item.product__sku }}</td>
          <td class="tables__cell">{{ item.product__description }}</td>
          <td class="tables__cell">
            <span class="tables__cell--align"
              >{{ item.expected_growth }} %
              <v-btn
                link
                x-small
                icon
                :to="{
                  name: 'AISearch',
                  params: {
                    product: item.product__sku + ': ' + item.product__description,
                  },
                }"
              >
                <v-icon> mdi-open-in-new </v-icon>
              </v-btn></span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <table class="tables__container" v-if="selectedTable === 'table2'">
      <thead class="tables__header">
        <tr class="tables__row">
          <th class="tables__cell-title" scope="col">{{ $t("sku") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("description") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("expectedGrowth") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in worstPerformances" :key="item.product__sku">
          <td class="tables__cell">{{ item.product__sku }}</td>
          <td class="tables__cell">{{ item.product__description }}</td>
          <td class="tables__cell">
            <span class="tables__cell--align"
              >{{ item.expected_growth }} %
              <v-btn
                link
                x-small
                icon
                :to="{
                  name: 'AISearch',
                  params: {
                    product: item.product__sku + ': ' + item.product__description,
                  },
                }"
              >
                <v-icon> mdi-open-in-new </v-icon>
              </v-btn></span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <h3 class="tables__title">
     {{ $t("potentialNewProducts") }}<span> {{ $t("toIncreaseBasket") }}</span>
    </h3>
    <table class="tables__container">
      <thead class="tables__header">
        <tr class="tables__row">
          <th class="tables__cell-title" scope="col">{{ $t("newProduct") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("segment") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("fromStore") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in potentialNewProducts" :key="item.NewProduct">
          <td class="tables__cell">{{ item.NewProduct }}</td>
          <td class="tables__cell">{{ item.Segment }}</td>
          <td class="tables__cell">{{ item.FromStore }}</td>
        </tr>
      </tbody>
    </table>
    <h3 class="tables__title">{{ $t("behaviouralChanges") }}</h3>
    <table class="tables__container">
      <thead class="tables__header">
        <tr class="tables__row">
          <th class="tables__cell-title" scope="col">{{ $t("sku") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("description") }}</th>
          <th class="tables__cell-title" scope="col">{{ $t("reason") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="change in behaviouralChanges" :key="change.SKU">
          <td class="tables__cell">{{ change.SKU }}</td>
          <td class="tables__cell">{{ change.Description }}</td>
          <td
            :class="
              change.Reason === 'Cannibalisation' ||
              change.Reason === 'Out of stock' ||
              change.Reason === 'Niche'
                ? 'tables__cell--change-color'
                : ''
            "
            class="tables__cell"
          >
            <span class="tables__cell--align"
              >{{ change.Reason }}
              <v-btn
                link
                x-small
                icon
                :to="{
                  name: 'AISearch',
                  params: {
                    product: change.SKU + ' : ' + change.Description,
                  },
                }"
              >
                <v-icon> mdi-open-in-new </v-icon>
              </v-btn>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import AppError from "@/utils/AppError.js";
export default {
  
  data() {
    return {
      selectedTable: "table1",
      bestPerformances: null,
      worstPerformances: null,
      potentialNewProducts: [
        {
          NewProduct: "RUCCOLA 125G NATURA E",
          Segment: `"0:GM SALMONE SCOZZERE AFFUM.100G", "1:INS.GENTILE OR. ITA AL PZ NATURA E", "2:SDC SALAME CONTADINO 500G",
            "3:PHILADELPHIA REGULAR 220G", "4:BIRAGHI SPICCHIO 100% IT 750 GR", "5:FERRERO NUTELA 900G", "6:OR FUNGHI
            COLTIVATI TRIFOLATI LATTA 180G"`,
          FromStore: "Store 38",
        },
        {
          NewProduct: "PY PATATE STEAKHOUSE 750G",
          Segment: `"0:BIBITA FRIZZANTE GUSTO ARANCIA PET 1.5L", "1:M235678 BEVANDA PESCA/UVA S/ZUCC. 3X0.2L", "1:M235678BEVANDA
            DI PERA S.ZUCCHERI 3X0.2L", "2:G SALAME DI PROSCIUTTO AL KG", "2:G M3678 PETTO TACCHINO ARROSTO AL KG", "3:LBP
            PANINI AL LATTE 400G"`,
          FromStore: "Store 7E",
        },
      ],
      behaviouralChanges: [
        {
          SKU: "12847122",
          Description: "RUCOLA 125G NATURA E",
          Reason: "Cannibalisation",
        },
        {
          SKU: "12847122",
          Description: "RUCOLA 125G NATURA E",
          Reason: "Store trend",
        },
        {
          SKU: "12847122",
          Description: "RUCOLA 125G NATURA E",
          Reason: "Country trend",
        },
        {
          SKU: "12847122",
          Description: "RUCOLA 125G NATURA E",
          Reason: "Out of stock",
        },
        {
          SKU: "12847122",
          Description: "RUCOLA 125G NATURA E",
          Reason: "Niche",
        },
      ],
      store: null,
    };
  },
  mounted: function () {
    this.getStore();
    this.getPerformances();
  },
  methods: {
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get best and worst performances data.
     * @public
     */
    getPerformances() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.performances;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.bestPerformances = resp.data.best_performances
          this.worstPerformances = resp.data.worst_performances
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
           this.$store.dispatch("util/stopLoading");
        });
    },
  }
};
</script>
