<template>
  <apexchart
    type="bar"
    height="360"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "HorizontalBarChart",

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      series: [
        {
          name: this.$t("weekday"),
          data: this.data.weekday,
        },
        {
          name: this.$t("weekend"),
          data: this.data.weekend,
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#0067FF", "#00204D"],
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "8px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [this.$t("morning"), this.$t("afternoon"), this.$t("night")],
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
          fontSize: "18px",
          fontFamily: "Aeonik",
          markers: {
            width: 20,
            height: 4,
            radius: 0,
          },
        },
      },
    };
  },
};
</script>

<style type="text/css">
.apexcharts-legend-marker {
  border-radius: 0 !important;
}
</style>
