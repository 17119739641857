<template>
  <apexchart
    type="radialBar"
    height="250"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "RadialBarChart",

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    data: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      series: [this.data],
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            track: {
              background: "#bfbfbf",
            },
            hollow: {
              size: "50%",
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -12,
                show: true,
                color: "#0044A9",
                fontSize: "14px",
              },
              value: {
                offsetY: 2,
                color: "#0044A9",
                fontSize: "25px",
                show: true,
                formatter: function (val) {
                  return (val * 5) / 100 + " / 5";
                },
              },
            },
          },
        },

        fill: {
          type: "solid",
          colors: ["#0044A9"],
        },

        labels: [this.$t("score")],
      },
    };
  },
};
</script>
